<template>
  <div class="bg-forgd-bgd-200 rounded-lg flex justify-between w-full">
    <div class="space-y-4 max-w-screen-sm flex-grow">
      <h1 class="text-forgd-primary-900 text-lg/[1.5] font-semibold">
        <slot name="title" />
      </h1>
      <p class="text-sm/[1.5] text-gray-600">
        <slot name="body" />
      </p>
    </div>

    <div>
      <slot name="actions" />
    </div>
  </div>
</template>
